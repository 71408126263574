<template>
  <div class="project-configuration-environment">
    <div>
      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('environment.info') }}
          </h2>
        </header>

        <div class="two-column-section__content">
          <v-card outlined>
            <v-card-text class="pb-0">
              <div class="d-flex space-x-4">
                <div class="project-configuration-environment__icon">
                  <label>{{ $t('color') }}</label>
                  <CyInputsColorPicker
                    :key="envCanonical"
                    icon="mdi-layers-outline"
                    :color-name.sync="formData.color"/>
                </div>
                <v-text-field
                  v-model="$v.formData.name.$model"
                  class="required-field"
                  :label="$t('forms.fieldName')"
                  :error-messages="nameErrors"
                  required
                  @blur="$v.formData.name.$touch()"/>
              </div>
            </v-card-text>
            <v-card-actions class="pl-4 pb-4">
              <CyButton
                type="submit"
                variant="primary"
                icon="done"
                :loading="saving"
                :disabled="!canSave"
                @click.native="updateEnv">
                {{ $t('forms.btnSave') }}
              </CyButton>
            </v-card-actions>
          </v-card>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section>
        <CyDangerZone
          v-has-rights-to="'UpdateProject'"
          class="two-column-section"
          :entity-name="envCanonical"
          :entity-type="_.toLower($t('Environment'))"
          :delete-button-text="`${$t('delete.title')}...`"
          :delete-title="$t('delete.title')"
          :delete-text="$t('delete.description')"
          @delete="deleteEnv">
          <template #confirmText>
            <p class="font-weight-bold mb-0">
              {{ $t('delete.modalDescriptionHeader') }}
            </p>
            <p class="font-weight-bold">
              {{ $t('delete.modalDescription') }}
            </p>
            <div class="d-flex align-center">
              <p class="ma-0 mr-2">
                {{ $t('delete.modalConfirm') }}
              </p>
              <span class="d-flex align-center font-weight-bold">
                <CyAvatar
                  :item="{
                    ...environment,
                    icon: 'mdi-layers-outline',
                  }"
                  class="mr-1"
                  sm/>
                {{ environment.name || envCanonical }}
              </span>
            </div>
          </template>
        </CyDangerZone>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CyDangerZone from '@/components/CyDangerZone.vue'
import CyInputsColorPicker from '@/components/CyInputsColorPicker.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageProjectConfigurationEnvironment',
  components: {
    CyInputsColorPicker,
    CyDangerZone,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.envCanonical, [
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectConfiguration'),
        name: 'projectConfiguration',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfiguration') }
  },
  validations () {
    return {
      formData: {
        name: {
          required,
        },
      },
    }
  },
  data: () => ({
    saving: false,
    environment: {},
    formData: {
      color: '',
      name: '',
    },
  }),
  computed: {
    ...mapState('organization/project', {
      errors: (state) => state.errors.env,
    }),
    ...mapGetters('organization/project', [
      'envs',
    ]),
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canSave () {
      return !this.$v.$invalid && this.$hasDataChanged('formData')
    },
  },
  watch: {
    envCanonical () {
      this.setup()
    },
  },
  created () {
    this.setup()
  },
  methods: {
    ...mapActions('organization/project', [
      'UPDATE_ENV',
      'DELETE_ENV',
    ]),
    setup () {
      const { envs, envCanonical } = this
      this.environment = _.find(envs, { canonical: envCanonical }) ?? {}
      this.formData = {
        name: _.get(this.environment, 'name', ''),
        color: _.get(this.environment, 'color', ''),
      }

      this.$setOriginalData()
    },
    async updateEnv () {
      const { formData: { name, color }, environment } = this
      const updatedEnv = {
        ...environment,
        name,
        color,
      }

      this.$toggle.saving(true)
      const success = await this.UPDATE_ENV({ environment: updatedEnv, refreshProject: true })
      this.$toggle.saving(false)

      success && this.$setOriginalData()
    },
    async deleteEnv () {
      await this.DELETE_ENV({ envCanonical: this.envCanonical })
      if (_.isEmpty(this.errors)) this.$router.push({ name: 'projectConfiguration' })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfiguration',
        delete: {
          title: '@:environment.delete',
          description: 'Deleting this environment is a permanent action that cannot be undone. Resources created through pipelines will not be deleted automatically.',
          modalDescriptionHeader: 'Deleting an environment will not delete resources that have been deployed by the pipeline.',
          modalDescription: 'Please remove them via the pipeline job or manually as they may generate costs.',
          modalConfirm: 'Do you still want to delete this environment?',
        },

      },
      es: {
        title: '@:routes.projectConfiguration',
        delete: {
          title: '@:environment.delete',
          description: 'Eliminar este entorno es una acción permanente que no se puede deshacer. Los recursos creados a través de pipelines no se eliminarán automáticamente.',
          modalDescriptionHeader: 'Borrar un entorno no borrará los recursos desplegados por la pipeline.',
          modalDescription: 'Por favor, bórrelos, usando la tarea de la pipeline o manualmente, ya que pueden generar costes.',
          modalConfirm: '¿Realmente quiere borrar este entorno?',
        },
      },
      fr: {
        title: '@:routes.projectConfiguration',
        delete: {
          title: `@:environment.delete`,
          description: 'La suppression de cet environnement est une action permanente et irréversible. Les ressources créées via les pipelines ne seront pas supprimées automatiquement.',
          modalDescriptionHeader: `La suppression d'un environnement ne supprimera pas les ressources déployées par la pipeline.`,
          modalDescription: 'Supprimez-les via la pipeline ou manuellement, car ils peuvent générer des coûts supplémentaires.',
          modalConfirm: 'Voulez-vous toujours supprimer cet environnement ?',
        },
      },
    },
  },
}
</script>

<style lang="scss">
.project-configuration-environment {
  &__icon {
    display: flex;
    flex-direction: column;
  }

  .color-picker {
    display: inline-block;

    &__card {
      position: absolute;
    }
  }

  label {
    color: cy-get-color("grey", "dark-2");
  }

  a {
    &:hover,
    &:focus,
    &:visited:hover {
      color: cy-get-color("grey");
    }
  }

  .danger-zone {
    &__title {
      @extend %two-column-section-header;
    }

    &__content {
      @extend %two-column-section-content;

      background-color: cy-get-color("white");
    }
  }
}
</style>
